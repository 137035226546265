<template>
  <a-modal
    width="700px"
    v-model:visible="visible"
    :title="$t('common.operation')"
    :mask-closable="false"
    :centered="true"
    :confirm-loading="loading"
    :ok-button-props="{ disabled: productImagesLoading || productLabelingLoading }"
    @ok="handleConfirm"
  >
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-item :label="$t('warehouse.se_box_number')">
        {{ boxInfo.boxNo || '-' }}
      </a-form-item>
      <a-form-item :label="$t('common.user_defined_box_no')">
        {{ boxInfo.selfBoxNo || '-' }}
      </a-form-item>
      <a-form-item
        :label="$t('warehouse.identifiable_product_information')"
        name="productIdentify"
      >
        <a-input v-model:value="formState.productIdentify" allow-clear></a-input>
      </a-form-item>
      <a-form-item
        :label="
          $t('warehouse.recognizable_product_images') +
          '(' +
          $t('common.optional') +
          ')'
        "
        name=""
      >
        <a-upload
          list-type="picture-card"
          accept=" .jpeg,.jpg,.png"
          v-model:file-list="formState.productIdentifyImgs"
          :customRequest="e => handleUpload(e, 'productIdentifyImgs')"
          @preview="handlePreview"
        >
          <div v-if="formState.productIdentifyImgs.length < 2">
            <loading-outlined v-if="productImagesLoading"></loading-outlined>
            <plus-outlined></plus-outlined>
            <div class="ant-upload-text">{{ $t("common.upload_img") }}</div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item
        :label="$t('warehouse.new_product_labeling_document')"
        name="productLabelUrl"
      >
        <a-upload
          list-type="picture-card"
          accept=" .jpeg,.jpg,.png,.pdf"
          v-model:file-list="formState.productLabelUrl"
          :customRequest="e => handleUpload(e, 'productLabelUrl')"
          @preview="handlePreview"
        >
          <div v-if="formState.productLabelUrl.length < 1">
            <loading-outlined v-if="productLabelingLoading"></loading-outlined>
            <plus-outlined v-else></plus-outlined>
            <div class="ant-upload-text">{{ $t('common.upload_img') }}</div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item
        :label="$t('warehouse.new_product_label')"
        name="newProductIdentify"
      >
        <a-input v-model:value="formState.newProductIdentify" allow-clear></a-input>
      </a-form-item>
      <a-form-item
        :label="$t('warehouse.product_quantity')"
        name="productCount"
      >
        <a-input-number v-model:value="formState.productCount" :min="1" :max="9999" :precision="0" style="width: 100%;"></a-input-number>
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
    <img style="width: 100%" :src="previewImage" />
  </a-modal>
</template>

<script>
import { Button, Form, Input, InputNumber, Modal, Spin, Upload } from "ant-design-vue";
import { LoadingOutlined } from '@ant-design/icons-vue';
import { defineComponent, reactive, ref, toRefs, h, nextTick } from "vue";
import urlHelper from "@/utils/urlHelper";
import { uploadImg } from "@/api/modules/common/index.js";
import { useI18n } from "vue-i18n/index";
import { setFormStateValue, randomString } from '@/utils/general.js';

export default defineComponent({
  emits: ['confirm'],
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    AInputNumber: InputNumber,
    AUpload: Upload,
    AButton: Button,
    ASpin: Spin,
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const formRef = ref(null);

    const state = reactive({
      visible: false,
      loading: false,
      productImagesLoading: false,
      productLabelingLoading: false,
      previewVisible: false,
      previewImage: null,
      boxInfo: {
        boxNo: null,
        selfBoxNo: null,
      },
      // 已录入可识别产品信息列表
      boxProductIdentifies: [],
      formState: {
        rowKey: null,
        detailId: null,
        productIdentify: null,
        productIdentifyImgs: [],
        productLabelUrl: [],
        newProductIdentify: null,
        productCount: null,
      },
    });

    const validateProductIdentify = (rule, value) => {
      if (!value) {
        return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.identifiable_product_information')]));
      }
      if (state.boxProductIdentifies.includes(value)) {
        return Promise.reject(vueI18n.t('warehouse.identifiable_product_information_already_exists'));
      }
      return Promise.resolve();
    }

    const stringReg = /^[\w-]+$/;
    const rules = {
      productIdentify: [
        {
          required: true,
          validator: validateProductIdentify,
        },
        {
          pattern: stringReg,
          message: () => vueI18n.t('common.p0_format_error', [vueI18n.t('warehouse.identifiable_product_information')]),
        },
      ],
      productLabelUrl: {
        required: true,
        type: 'array',
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.new_product_labeling_document')]),
      },
      newProductIdentify: [
        {
          required: true,
          message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.new_product_label')]),
        },
        {
          pattern: stringReg,
          message: () => vueI18n.t('common.p0_format_error', [vueI18n.t('warehouse.new_product_label')]),
        },
      ],
      productCount: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.product_quantity')]),
      },
    };

    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '14px',
        color: "#aaa",
      },
      spin: true,
    });

    const handlePreview = (file) => {
      if (!file.url) return;
      state.previewImage = file.url;
      state.previewVisible = true;
    }

    const handleUpload = (files, key) => {
      let uploadData = new FormData();
      uploadData.append("file", files.file);
      uploadData.append("functionModule", 24);
      if (key === 'productIdentifyImgs') {
        state.productImagesLoading = true;
      } else if (key === 'productLabelUrl') {
        state.productLabelingLoading = true;
      }
      uploadImg(uploadData)
        .then(({ result }) => {
          if (Array.isArray(result) && result.length > 0) {
            let filesList = state.formState[key];
            let fileItem = filesList.find(item => item.uid === files.file.uid);
            let res = result[0];
            fileItem.status = 'done';
            fileItem.url = res.url;
            if (key === 'productLabelUrl' && res.parsingCode) {
              state.formState.newProductIdentify = res.parsingCode;
              formRef.value.validate(['newProductIdentify']);
            }
          }
        })
        .catch(() => {
          let filesList = state.formState[key];
          let index = filesList.findIndex(item => item.uid === files.file.uid);
          filesList.splice(index, 1);
        })
        .finally(() => {
          if (key === 'productIdentifyImgs') {
            state.productImagesLoading = false;
          } else if (key === 'productLabelUrl') {
            state.productLabelingLoading = false;
          }
        });
    };

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        const data = Object.assign({}, state.formState);
        emit('confirm', data);
        close();
      } catch (error) {
      }
    }

    const open = ({ detailId, box, productData }, record) => {
      state.visible = true;
      state.boxInfo = box;
      state.formState.detailId = detailId;
      state.boxProductIdentifies = productData.map(item => item.productIdentify);
      nextTick(() => {
        formRef.value.resetFields();
        state.formState.productIdentifyImgs = [];

        if (record) {
          let boxProductIdentifiesIndex = state.boxProductIdentifies.findIndex(item => item === record.productIdentify);
          if (boxProductIdentifiesIndex > -1) {
            state.boxProductIdentifies.splice(boxProductIdentifiesIndex, 1);
          }
          setFormStateValue(state.formState, record);
        } else {
          state.formState.rowKey = randomString(12);
        }
      })
    }

    const close = () => {
      state.visible = false;
    }

    return {
      ...toRefs(state),
      urlHelper,
      formRef,
      rules,
      indicator,
      labelCol: { span: 9 },
      wrapperCol: { span: 12 },
      handlePreview,
      handleUpload,
      handleConfirm,
      open,
      close,
    };
  },
});
</script>

<style lang="less" scoped>
.pabi-img {
  max-width: 86px;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  :deep(img) {
    max-height: 86px;
    max-width: 86px;
  }
}
</style>
