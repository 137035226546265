<template>
  <a-spin :spinning="loading">
    <a-row :gutter="[8, 8]">
      <a-col :span="24">{{ $t('warehouse.label_task_name') }}: {{ pageInfo.taskName }}</a-col>
      <a-col :span="24">{{ $t('warehouse.tag_task_number') }}: {{ pageInfo.taskNo }}<a-button size="small" style="margin-left: 5px;" danger @click="handleCancel">{{ $t('common.cancel') }}</a-button></a-col>
    </a-row>
    <a-table
      style="min-width: 500px; overflow-x: auto;"
      class="mt-3"
      size="small"
      :columns="columns"
      :data-source="shipmentList"
      v-model:expanded-row-keys="boxExpandedRowKeysList"
      :pagination="false"
      :row-key="(record) => record.detailId"
      @expand="handleExpandRow"
    >
      <template #serialNumber="{ index, record }">
        {{ index + 1 }}
      </template>
      <template #boxNo="{ record }">
        {{ record.box?.boxNo || '-' }}
      </template>
      <template #selfBoxNo="{ record }">
        {{ record.box?.selfBoxNo || '-' }}
      </template>
      <template #operation="{ record }">
        <a-button type="primary" ghost @click="handleOpenModal(record)">{{ $t('warehouse.add_product_tags') }}</a-button>
      </template>
      <template #expandedRowRender="{ record }">
        <a-table
          :columns="innerColumns"
          :data-source="record.productData"
          :pagination="false"
          :row-key="(record) => record.rowKey"
        >
          <template #serialNumber="{ index }">
            {{ index + 1 }}
          </template>
          <template #productIdentifyImgs="{ record }">
            <a-row :gutter="8" v-if="record.productIdentifyImgs.length">
              <a-col v-for="(item, index) in record.productIdentifyImgs" :key="index">
                <CFileDisplay :fileUrl="item.url" />
              </a-col>
            </a-row>
            <span v-else>-</span>
          </template>
          <template #productLabelUrl="{ record }">
            <CFileDisplay :fileUrl="record.productLabelUrl[0].url" />
          </template>
          <template #operation="{ record, index }">
            <a-row :gutter="16">
              <a-col>
                <a-button type="ghost" @click="handleEdit(record)">{{ $t('common.edit') }}</a-button>
              </a-col>
              <a-col>
                <a-button type="ghost" @click="handleDelete(record, index)">{{ $t('common.remove') }}</a-button>
              </a-col>
            </a-row>
          </template>
        </a-table>
      </template>
    </a-table>
    <a-row class="mt-5 mb-3" type="flex" justify="end">
      <a-col class="mr-3">
        <a-button type="primary" :loading="confirmLoading" @click="handleConfirm">{{ $t('warehouse.save_and_next') }}</a-button>
      </a-col>
    </a-row>
  </a-spin>
  <AddProductLabels ref="addProductLabelsRef" @confirm="handleSetTable" />
</template>

<script>
import { defineComponent, reactive, toRefs, ref, watch } from 'vue';
import { Button, Checkbox, Col, Input, InputNumber, Modal, Row, Spin, Table, message } from 'ant-design-vue';
import CFileDisplay from '@/views/components/CFileDisplay.vue';
import AddProductLabels from './AddProductLabels.vue';
import { useI18n } from "vue-i18n/index";
import { useRouter } from 'vue-router';
import {
  getSecondStep,
  createSecondStep,
  cancelStorageUserLabelChange,
} from '@/api/modules/storage/labelChange.js';
import { setFormStateValue } from '@/utils/general.js';
import { useTab } from "@/hooks/tabs/index";

export default defineComponent({
  name: 'stepTwo',
  components: {
    ASpin: Spin,
    ARow: Row,
    ACol: Col,
    AButton: Button,
    ATable: Table,
    AInputNumber: InputNumber,
    ACheckbox: Checkbox,
    AInput: Input,
    CFileDisplay,
    AddProductLabels,
  },
  props: {
    taskId: String,
  },
  setup (props) {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const { delVisitedRoute } = useTab();

    const addProductLabelsRef = ref(null);

    const state = reactive({
      loading: false,
      confirmLoading: false,
      formState: {
        taskId: null,
      },
      pageInfo: {
        taskName: null,
        taskNo: null,
      },
      shipmentList: [],
      // 展开行
      boxExpandedRowKeysList: [],
    })

    const columns = [
      {
        width: 80,
        title: () => vueI18n.t('warehouse.serial_number'),
        slots: {
          customRender: 'serialNumber',
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('warehouse.se_box_number'),
        slots: {
          customRender: 'boxNo',
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('common.user_defined_box_no'),
        slots: {
          customRender: 'selfBoxNo',
        }
      },
      {
        width: 120,
        slots: {
          customRender: 'operation',
        }
      },
    ];

    const innerColumns = [
      {
        width: 80,
        title: () => vueI18n.t('warehouse.serial_number'),
        slots: {
          customRender: 'serialNumber',
        }
      },
      {
        dataIndex: 'productIdentify',
        width: 120,
        title: () => vueI18n.t('warehouse.identifiable_product_information'),
      },
      {
        width: 150,
        title: () => vueI18n.t('warehouse.recognizable_product_images'),
        slots: {
          customRender: 'productIdentifyImgs',
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('warehouse.new_product_labeling_document'),
        slots: {
          customRender: 'productLabelUrl',
        }
      },
      {
        dataIndex: 'newProductIdentify',
        width: 120,
        title: () => vueI18n.t('warehouse.new_product_label'),
      },
      {
        dataIndex: 'productCount',
        width: 100,
        title: () => vueI18n.t('warehouse.product_quantity'),
      },
      {
        width: 150,
        slots: {
          customRender: 'operation',
        }
      },
    ];

    const handleBack = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push('/storage/labelchange');
    };

    const handleExpandRow = (expand, record) => {
      if (expand) {
        state.boxExpandedRowKeysList.push(record.detailId);
      } else {
        state.boxExpandedRowKeysList = state.boxExpandedRowKeysList.filter(item => item !== record.detailId);
      }
    }

    const handleCancel = () => {
      Modal.confirm({
        title: vueI18n.t("common.operation"),
        content: vueI18n.t("common.are_you_sure_cancel"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          return cancelStorageUserLabelChange({ taskId: state.formState.taskId }).then(() => {
            message.success(vueI18n.t("common.successfully_canceled"));
            handleBack();
          });
        },
      });
    }

    const handleOpenModal = ({ detailId, box, productData }, record = null) => {
      let data = { detailId, box, productData }
      addProductLabelsRef.value.open(data, record);
    }

    const handleSetTable = (dataRow) => {
      let { detailId, rowKey } = dataRow;
      state.shipmentList.forEach(item => {
        if (item.detailId === detailId) {
          let product = item.productData.find(item => item.rowKey === rowKey);
          if (product) {
            setFormStateValue(product, dataRow);
          } else {
            item.productData.push(dataRow);
          }
        }
      })
    }

    const handleEdit = (record) => {
      let shipment = state.shipmentList.find(item => item.detailId === record.detailId);
      if (shipment) {
        handleOpenModal(shipment, record);
      }
    }

    const handleDelete = (record, index) => {
      let rowIndex = state.shipmentList.findIndex(item => item.detailId === record.detailId);
      if (rowIndex > -1) {
        state.shipmentList[rowIndex].productData.splice(index, 1);
      }
    }

    // 获取提交数据
    const getBoxDetailData = () => {
      let boxDetail = state.shipmentList.map(item => {
        let productData = item.productData.map(item => ({
          productIdentify: item.productIdentify,
          productIdentifyImgs: item.productIdentifyImgs.map(item => item.url),
          productLabelUrl: item.productLabelUrl.map(item => item.url)[0],
          newProductIdentify: item.newProductIdentify,
          productCount: item.productCount,
        }));

        return {
          detailId: item.detailId,
          productData,
        }
      });

      return boxDetail;
    }

    // 验证
    const validateFormData  = (data) => {
      if (!data.taskId) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.label_task')]));
        return false;
      }

      if (!data.boxDetail) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.box_information')]));
        return false;
      }

      let result = true;
      let errorProductNo = null;
      for (let i = 0; i < data.boxDetail.length; i++) {
        let item = data.boxDetail[i];
        if (item.productData.length === 0) {
          result = false;
          state.boxExpandedRowKeysList = Array.from(new Set([...state.boxExpandedRowKeysList, item.detailId]));
          let boxInfo = state.shipmentList.find(shipment => shipment.detailId === item.detailId);
          if (boxInfo) {
            errorProductNo = boxInfo.box.boxNo;
          }
          break;
        }
      }

      if (!result) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.product_info')]) + ' [' + errorProductNo + ']');
      }

      return result;
    }

    const handleConfirm = () => {
      let boxDetail = getBoxDetailData();
      const data = {
        taskId: state.formState.taskId,
        boxDetail,
      }
      let validateRes = validateFormData(data);
      if (validateRes) {
        state.confirmLoading = true;
        createSecondStep(data).then(() => {
          message.success(vueI18n.t('common.succeed'));
          handleNext();
        }).catch(() => {}).finally(() => {
          state.confirmLoading = false;
        });
      }
    }

    const handleNext = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push('/storage/labelchange/confirm/' + state.formState.taskId);
    }

    const getSecondStepDetail = () => {
      state.loading = true;
      getSecondStep({ taskId: state.formState.taskId }).then(({ result }) => {
        setFormStateValue(state.pageInfo, result);
        let boxExpandedRowKeysList = [];
        state.shipmentList = result.items.map(item => {
          boxExpandedRowKeysList.push(item.detailId);
          item.productData = [];
          return item;
        });
        state.boxExpandedRowKeysList = boxExpandedRowKeysList;
      }).catch(() => {}).finally(() => {
        state.loading = false;
      })
    }

    watch(() => props.taskId, (value) => {
      if (value) {
        state.formState.taskId = value;
        getSecondStepDetail();
      }
    }, { immediate: true });

    return {
      ...toRefs(state),
      addProductLabelsRef,
      columns,
      innerColumns,
      handleExpandRow,
      handleCancel,
      handleOpenModal,
      handleSetTable,
      handleEdit,
      handleDelete,
      handleConfirm,
    }
  }
})
</script>

<style scoped>

</style>